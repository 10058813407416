import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import mixin from './mixin'
import dayjs from 'dayjs'

Vue.use(dayjs)
Vue.mixin(mixin)
Vue.config.productionTip = false

const vue = new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
})
vue.$mount('#app')
window.thisVue = vue
