import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    breakpoint: {
        thresholds: {
            xs: 480,
            sm: 860,
            md: 1200,
            lg: 1920,
        },
    }
});
