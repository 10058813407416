<template>
  <v-snackbar
    v-model="lazyValue"
    :timeout="timeout"
    top
    max-width="640"
    :min-width="`unset`"
    :width="`100%`"
    color="#1a1a1a"
    rounded="lg"
  >
    <!-- color="blue-grey darken-4" -->
    <v-layout align-center>
      <span class="txt-msg flex-grow-1" :class="{ 'text-center': !showClose }">
        {{ lazyMsg }}
      </span>
    </v-layout>
    <template v-if="showClose" v-slot:action="{ attrs }">
      <span
        color="white"
        text
        v-bind="attrs"
        @click.prevent.stop="() => (lazyValue = false)"
      >
        {{ txtClose }}
      </span>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      default: false,
    },
    timeout: {
      default: 10000,
    },
    msg: {
      default: "",
    },
    txtClose: {
      default: "닫기",
    },
    callback: {
      default: () => {},
    },
    isIc: {
      default: false,
    },
    showClose: {
      default: false,
    },
  },
  components: {},
  data() {
    return {
      lazyValue: this.value,
      lazyTime: this.timeout,
      lazyMsg: this.msg,
    };
  },
  computed: {},
  watch: {
    msg() {
      this.lazyMsg = this.msg;
    },
    timeout() {
      this.lazyTime = this.timeout;
    },
    value() {
      this.lazyValue = this.value;
    },
    lazyValue() {
      if (this.callback) {
        this.callback(this.lazyValue);
      }
    },
  },
  created() {},
  mounted() {},
  destroyed() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "../sass/app.scss";
.txt-msg {
  font-style: normal;
  font-weight: 400;
  font-size: pxToRem(16px);
  line-height: pxToRem(24px);
  color: #ffffff;
}
</style>
