const Main = () => import('@/views/main.vue').then(m => m.default || m)
const Survey = () => import('@/views/survey/survey.vue').then(m => m.default || m)
const event5 = () => import('@/views/event/event_5.vue').then(m => m.default || m)
const event5Reg = () => import('@/views/event/event_5_reg.vue').then(m => m.default || m)

export default [
    {
        path: '',
        name: 'main',
        component: Main,
        props: true,
        meta: { footer: true },
    },
    {
        path: '/survey',
        name: 'survey',
        component: Survey,
        props: true,
        meta: { footer: true }
    },
    
    {
        path: '/event5',
        name: 'event5',
        component: event5,
        props: true,
        meta: { footer: true }
    },
    {
        path: '/event5Reg',
        name: 'event5Reg',
        component: event5Reg,
        props: true,
        meta: { footer: true }
    },
    {
        path: '*',
        redirect: '/',
    },
]